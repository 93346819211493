import './albumPreview.css'

import React, { PureComponent } from 'react' // eslint-disable-line no-unused-vars
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import classNames from 'classnames'

import { Photo, Tags } from '@instaglam/component'

export class AlbumPreview extends PureComponent {
  static defaultProps = {
    id: null,
    title: null,
    tags: { edges: [] },
    owner: { name: 'Snoop Dogg' },
    photos: { edges: [] },
    isFeatured: false
  }



  render () {
    const { id, title, owner, tags, photos, isFeatured } = this.props
    const albumLink = `/album/${id}`;

    return (
      <div className={classNames('album-preview', {
        'album-preview--featured': isFeatured
      })}>
        <Link to={albumLink}>
          <div className='album-preview__photos'>
            {(isFeatured) ? <div className='album-preview__featured-badge'>🔥 FEATURED</div> : null}
            <div className='album-preview__cover-photo'>
              {(photos.edges.length) ? <Photo {...photos.edges[0].node} size='small' /> : null}
            </div>
          </div>
        </Link>

        <Link to={albumLink}>
          <div className='album-preview__title'>{title}</div>
        </Link>

        <div className='album-preview__meta'>
          {(tags.edges.length) ? <Tags className='album-preview__tags' tags={tags} /> : null}
          <div>{photos.totalCount} photos · Curated by <Link to='/user/9061555c-3b81-11e9-b210-d663bd873d93'>{owner.name}</Link></div>
        </div>
      </div>
    )
  }
}

export function mapStateToProps () {
  return {}
}

export function mapDispatchToProps () {
  return {}
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AlbumPreview)
)
