import { createStore, compose, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { createBrowserHistory } from 'history'
import { rootReducer } from '@instaglam/module/rootReducer'

export const history = createBrowserHistory()

export const configureStore =
  (initialState = {}) => {
    const middleware = [
      createSagaMiddleware()
    ]

    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

    const store = createStore(
      rootReducer,
      composeEnhancers(applyMiddleware(...middleware))
    )

    store.runSaga = middleware[0].run

    return { store }
  }

export default configureStore
