import './albumScreen.css'

import React from 'react' // eslint-disable-line no-unused-vars
import { Link } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'
import Masonry from 'react-masonry-css'

import { Error, Photo } from '@instaglam/component'

export const AlbumScreen = ({ match }) => {
  const { loading, error, data } = useQuery(
    gql`
      query($id: String!) {
        album(id: $id) {
          id
          title

          photos {
            edges {
              node {
                id
              }
            }
          }
        }
      }
    `, {
      variables: {
        id: match.params.id
      }
    }
  )

  if (loading) {
    return (
      <div>Loading...</div>
    )
  }

  if (error) {
    return (
      <Error />
    )
  }

  const { title, photos } = data.album

  return (
    <div className='album-screen'>
      <h1 className='instaglam-logo'>📸 Instaglam</h1>

      <div className='album'>
        <Link to='/'>← Explore</Link>

        <div className='album-screen__title'>{title}</div>
        <div className='album-screen__photos'>
          <Masonry
            breakpointCols={{ default: 3, 1000: 2, 800: 1 }}
            className='album-screen__photos-grid'
            columnClassName="album-screen__photos-grid__column"
          >
            {photos.edges.map((edge, index) => {
              const photo = edge.node
              return (
                <Photo
                  key={`${index}-${photo.id}`}
                  id={photo.id}
                  size='small'
                  showDescription
                />
              )
            })}
          </Masonry>
        </div>
      </div>
    </div>
  )
}

export default AlbumScreen
