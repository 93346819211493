import './error.css'

import React from 'react' // eslint-disable-line no-unused-vars

export function Error () {
  return (
    <div className='error'>
      <iframe src='https://giphy.com/embed/xT9IgIc0lryrxvqVGM' width='480' height='289' frameBorder='0' allowFullScreen></iframe>
    </div>
  )
}

export default Error
