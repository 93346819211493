import React, { Component } from 'react' // eslint-disable-line no-unused-vars
import { hot } from 'react-hot-loader/root'
import { connect } from 'react-redux'
import { withRouter, Switch, Route, BrowserRouter } from 'react-router-dom'

import ExploreScreen from '@instaglam/screen/exploreScreen/exploreScreen'
import AlbumScreen from '@instaglam/screen/albumScreen/albumScreen'

export class Router extends Component {
  render () {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path='/' component={ExploreScreen} />
          <Route exact path='/album/:id' component={AlbumScreen} />
        </Switch>
      </BrowserRouter>
    )
  }
}

export function mapStateToProps (state, props) {
  return {}
}

export function mapDispatchToProps (dispatch) {
  return {}
}

export default hot(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Router)
)
