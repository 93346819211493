export const createReducer = (initialState, handlers) => {
  if (process.env.NODE_ENV !== 'production' && handlers['undefined']) {
    console.warn(
      'Reducer contains an \'undefined\' action type. ' +
      'Have you misspelled a constant?'
    )
  }

  const reducer = function (state, action) {
    if (state === undefined) {
      state = initialState
    }

    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action)
    }

    return state
  }

  return reducer
}

export default createReducer
