import './photo.css'

import React from 'react' // eslint-disable-line no-unused-vars
import { gql, useQuery } from '@apollo/client'

export const Photo = ({ id, size, showDescription }) => {
  const { data } = useQuery(
    gql`
      query($id: String!) {
        photo(id: $id) {
          description
          sizes {
            small
            full
          }
        }
      }
    `, {
      variables: {
        id
      }
    }
  )

  try {
    return (
      <div className={['photo', showDescription && 'photo--with-description'].filter(c => c).join(' ')}>
        <img src={data.photo.sizes[size]} />
        {showDescription && (
          <div className='photo__description'>{data.photo.description}</div>
        )}
      </div>
    )
  } catch {
    return null
  }
}

export default Photo
