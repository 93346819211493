import './tags.css'

import React from 'react' // eslint-disable-line no-unused-vars

import { Tag } from '@instaglam/component'

export function Tags ({ tags }) {
  return (
    <div className='tags'>
      {tags.edges.map((edge) => <Tag key={edge.node.id} {...edge.node} />)}
    </div>
  )
}

export default Tag
