import './exploreScreen.css'

import React, { Component } from 'react' // eslint-disable-line no-unused-vars
import { connect } from 'react-redux'
import { gql } from '@apollo/client'
import { Query } from '@apollo/client/react/components'

import { Error, AlbumPreview } from '@instaglam/component'
import { node } from 'prop-types'

export class ExploreScreen extends Component {
  render () {
    return (
      <div className='explore-screen'>
        <h1 className='instaglam-logo'>📸 Instaglam!!!!</h1>

        <div className='album-previews'>
          <Query query={
            gql`
              query {
                albums (first: 50) {
                  edges {
                    node {
                      id
                      title
                      isFeatured

                      owner {
                        name
                      }

                      photos (first: 1) {
                        totalCount
                        edges {
                          node {
                            id
                          }
                        }
                      }

                      tags {
                        edges {
                          node {
                            id
                            name
                          }
                        }
                      }
                    }
                  }
                }
              }
            `
          }>
            {({ loading, error, data }) => {
              if (loading) {
                return (
                  <div>Loading...</div>
                )
              }

              if (error) {
                return (
                  <Error />
                )
              }

              return data.albums.edges.map((edge) => {
                const album = edge.node
                return (
                  <AlbumPreview
                    key={album.id}
                    id={album.id}
                    title={album.title}
                    owner={album.owner}
                    tags={album.tags}
                    photos={album.photos}
                    isFeatured={album.isFeatured}
                  />
                )
              })
            }}
          </Query>
        </div>
      </div>
    )
  }
}

export function mapStateToProps (state, props) {
  return {}
}

export function mapDispatchToProps (dispatch) {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExploreScreen)
