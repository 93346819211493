import './tag.css'

import React from 'react' // eslint-disable-line no-unused-vars

export function Tag ({ name }) {
  return (
    <div className='tag'>#{name}</div>
  )
}

export default Tag
