import './entrypoint.css'
import 'bootstrap/dist/css/bootstrap.css'

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ApolloProvider } from '@apollo/client'

import { Router } from '@instaglam/component'
import rootSaga from '@instaglam/module/rootSaga'
import configureStore from '@instaglam/store/configureStore'
import { client } from '@instaglam/service/apollo'

const state = {}
const { store } = configureStore(state)

const Application = (
  <Provider store={store}>
    <ApolloProvider client={client}>
      <Router />
    </ApolloProvider>
  </Provider>
)

store.runSaga(rootSaga)
ReactDOM.render(Application, document.getElementById('root'))
